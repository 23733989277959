'use client';

import { useMemo, useState, useCallback, createContext, useEffect } from 'react';

import { useCookies } from 'src/hooks/use-cookies';
import { useLocalStorage } from 'src/hooks/use-local-storage';

import { STORAGE_KEY, defaultSettings } from '../config-settings';
import TiktokPixel from 'tiktok-pixel';


// ----------------------------------------------------------------------

export const SettingsContext = createContext(undefined);

export const SettingsConsumer = SettingsContext.Consumer;

// ----------------------------------------------------------------------

export function SettingsProvider({ children, settings, caches = 'localStorage', configData }) {
  const cookies = useCookies(STORAGE_KEY, settings, defaultSettings);

  const localStorage = useLocalStorage(STORAGE_KEY, settings);
  const [reactPixel, setReactPixel] = useState(null)

  const values = caches === 'cookie' ? cookies : localStorage;

  const [openDrawer, setOpenDrawer] = useState(false);

  const onToggleDrawer = useCallback(() => {
    setOpenDrawer((prev) => !prev);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  useEffect(() => {
    if (!configData) {
      return
    }
    if (!configData?.facebook_pixel) {
      return
    }
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {

        if (reactPixel) {
          return;
        }
        ReactPixel.init(configData.facebook_pixel) // facebookPixelId
        ReactPixel.pageView()
        setReactPixel(ReactPixel);

      })
  }, [configData])

  useEffect(() => {
    if (!configData) {
      return
    }
    if (!configData?.tiktok_token) {
      return
    }
    TiktokPixel.init(configData.tiktok_token) // facebookPixelId
    TiktokPixel.pageView()

  }, [configData])

  const memoizedValue = useMemo(
    () => ({
      ...values.state,
      canReset: values.canReset,
      onReset: values.resetState,
      onUpdate: values.setState,
      onUpdateField: values.setField,
      openDrawer,
      onCloseDrawer,
      onToggleDrawer,
      reactPixel
    }),
    [
      values.canReset,
      values.resetState,
      values.setField,
      values.setState,
      values.state,
      openDrawer,
      onCloseDrawer,
      onToggleDrawer,
      reactPixel
    ]
  );

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}
